<template>
  <div class="container">
    <Form action="" class="form form_col" @submit="handleSubmit">
      <fieldset class="form__titleWrap">
        <legend class="form__title">
          <h1 class="titlePage titlePage_textLeft">Sign up as a wholesale customer</h1>
        </legend>
        <span class="text text_italic">*Required Fields</span>
      </fieldset>
      <div class="form__col_2">
        <h2 class="titlePage titlePage_subtitle titlePage_green">About you</h2>
        <p class="text">Please enter your details below</p>

        <label class="form__label">
          <span class="form__labelTitle">First Name*</span>
          <Field type="text" class="form__input" name="first name" placeholder="First Name*"
                 v-model="user.name"
                 rules="required|max:128"
          />
          <ErrorMessage class="form__error"  name="first name" />
        </label>

        <label class="form__label">
          <span class="form__labelTitle">Last Name*</span>
          <Field type="text" class="form__input" name="last name" placeholder="Last Name*"
                 v-model="user.last_name"
                 rules="required|max:128"
          />
          <ErrorMessage class="form__error"  name="last name" />
        </label>
        <label class="form__label">
          <span class="form__labelTitle">Mobile Number*</span>
          <Field class="form__input"
                    v-maska="'## ## ### ###'"
                    name="mobile number"
                    placeholder="Mobile Number*"
                    v-model="user.mobile_phone"
                    rules="required|max:13|min:6"
                    @input="actionsOnMobile"
          />
          <ErrorMessage class="form__error"  name="mobile number" />
        </label>
        <label class="form__label">
          <span class="form__labelTitle">E-mail*</span>
          <Field type="text" class="form__input" name="email"
                 placeholder="E-mail*"
                 ref="email"
                 rules="required|max:128|email"
          />
          <ErrorMessage class="form__error"  name="email" />
        </label>
        <label class="form__label">
          <span class="form__labelTitle">Confirm E-mail*</span>
          <Field type="text" class="form__input" name="confirm e-mail"
                 placeholder="Confirm E-mail*"
                 v-model="user.email"
                 label="email"
                 :rules="'required|confirmed:@email'"
                 @input="actionsOnEmail"
          />
          <ErrorMessage class="form__error"  name="confirm e-mail" />
        </label>


        <h2 class="titlePage titlePage_subtitle titlePage_green">About your business</h2>
        <p class="text">Please enter your business details below</p>

        <label class="form__label ">
          <span class="form__labelTitle">ABN*</span>
          <Field type="text" class="form__input" name="ABN" placeholder="ABN*"
                 v-model="user.abn"
                 :rules="{required: true, digits: 11, numeric: true}"
          />
          <ErrorMessage class="form__error"  name="ABN" />
        </label>

        <label class="form__label form__label_row">
          <input type="checkbox" class="form__radio"
                 hidden
                 v-model="user.register_for_gst"
                 value="true"
          >
          <span class="form__radio_icon"></span>
          <span class="form__labelTitle">Registered for GST?</span>
        </label>

        <label class="form__label ">
          <span class="form__labelTitle">Business Name*</span>
          <Field type="text" class="form__input"
                 name="Business Name"
                 placeholder="business Name*"
                 v-model="user.business_name"
                 :rules="{required: true}"
                 @input="actionsOnName"
          />
          <ErrorMessage class="form__error"  name="business Name" />
        </label>
        <label class="form__label">
          <span class="form__labelTitle">Trading Name*</span>
          <Field type="text" class="form__input"
                 name="trading name"
                 placeholder="Trading Name*"
                 v-model="user.trading_name"
                 :rules="{required: true, max: 255}"
                 @input="disableSame('sameTredName')"
                 :disabled="sameTredName"
          />
          <ErrorMessage class="form__error"  name="trading name" />
        </label>


        <label class="form__label form__label_row">
          <input type="checkbox" class="form__radio"
                 hidden
                 :disabled="user.business_name === ''"
                 v-model="sameTredName"
          >
          <span class="form__radio_icon"></span>
          <span class="form__labelTitle">Same as business name</span>
        </label>


        <label class="form__label ">
          <span class="form__labelTitle">Phone*</span>
          <Field class="form__input"
                    v-maska="'## ## ### ###'"
                    name="business phone"
                    placeholder="Phone*"
                    v-model="user.phone"
                    rules="required|max:13|min:6"
                    :disabled="samePhone"
          />
          <ErrorMessage class="form__error"  name="business phone" />
        </label>

        <label class="form__label form__label_row">
          <input type="checkbox" class="form__radio"
                 hidden
                 :disabled="user.mobile_phone === ''"
                 v-model="samePhone"
          >
          <span class="form__radio_icon"></span>
          <span class="form__labelTitle">Same as above</span>
        </label>

        <label class="form__label">
          <span class="form__labelTitle">E-mail</span>
          <Field type="text" class="form__input"
                 name="business e-mail"
                 placeholder="E-mail"
                 v-model="user.business_email"
                 :rules="'email'"
                 @input="disableSame('sameEmail')"
                 :disabled="sameEmail"
          />

          <ErrorMessage class="form__error"  name="business e-mail" />
        </label>

        <label class="form__label form__label_row">
          <input type="checkbox" class="form__radio"
                 hidden
                 :disabled="user.email === ''"
                 v-model="sameEmail"
          >
          <span class="form__radio_icon"></span>
          <span class="form__labelTitle">Same as above</span>
        </label>

        <p class="text">Please enter your business address</p>

        <BaseSingleAddressComponent
          :address.sync="user ?? {}"
          ref="BaseSingleAddressComponent"
        />
      </div>

      <div class="form__col_2">
        <p class="text">Please enter your qualification details including licences you hold</p>

        <label class="form__label">
          <span class="form__labelTitle">Qualifications*</span>
          <Field as="textarea" rows="5" class="form__input" name="aualifications" placeholder="Qualifications*"
                    v-model="user.qualification"
          >
          </Field>
          <ErrorMessage class="form__error"  name="qualifications" />
        </label>

        <p class="text">Please select your public liability insurance level</p>
        <label class="form__label">
          <span class="form__labelTitle">Public Liability</span>
          <select class="form__input" name="liability"
                  v-model="user.public_liability_id"
                  :style="opacity(user.public_liability_id)"
          >
            <option
              v-for="(item, index) in liabilities"
              :value="item.id"
              :key="index"
            >
              {{item.name}}
            </option>
            {{liabilities}}
          </select>
        </label>


        <h2 class="titlePage titlePage_subtitle titlePage_green">Your experience</h2>
        <p class="text">How many screens or doors have you or your business installed in the last 5 years?</p>

        <div class="form__wrapRadio">
          <label class="form__label form__label_row"
                 v-for="(item, index) in experience"
                 :key="index"
          >
            <Field type="radio" name="quantity install"
                   :value="item.id"
                   hidden class="form__radio"
                   :rules="{required: true}"
                   v-model="user.experience_id"
            />
            <span class="form__radio_icon"></span>
            <span class="form__labelTitle">{{item.name}}</span>
          </label>
          <ErrorMessage class="form__error"  name="quantity install" />
        </div>

        <p class="text">Number of employees</p>

        <div class="form__wrapRadio">
          <label class="form__label form__label_row"
                 v-for="(item, index) in owners"
                 :key="index"
          >
            <Field type="radio" name="number of employees"
                   :value="item.id"
                   :rules="{required: true}"
                   hidden class="form__radio"
                   v-model="user.number_of_employers_id"
            />
            <span class="form__radio_icon"></span>
            <span class="form__labelTitle">{{item.name}}</span>
          </label>
          <ErrorMessage class="form__error"  name="number of employees" />
        </div>

        <h2 class="titlePage titlePage_subtitle titlePage_green">Would like to apply for a trading account ?</h2>

        <div class="form__wrapRadio form__wrapRadio_row" :key="key">
          <label class="form__label form__label_row">
            <Field type="radio" name="trading" hidden class="form__radio"
                   :value="true"
                   :rules="{required: user.require_trading_account === ''}"
                   v-model="user.require_trading_account"
            />
            <span class="form__radio_icon"></span>
            <span class="form__labelTitle">Yes</span>
          </label>

          <label class="form__label form__label_row">
            <Field type="radio" name="trading" hidden class="form__radio"
                   :value="false"
                   :rules="{required: user.require_trading_account === ''}"
                   v-model="user.require_trading_account"
            />
            <span class="form__radio_icon"></span>
            <span class="form__labelTitle">No</span>
          </label>
          <ErrorMessage  class="form__error"  name="trading" />
        </div>
        <h2 class="titlePage titlePage_subtitle titlePage_green">Submit your application</h2>
        <p class="text">By submitting this application you accept Starline Security
          <router-link  :to="{name: 'infoPage'}" class="text_green" target="_blank">Terms & conditions</router-link>
        </p>
        <p class="text text_mb20">
          <button type="submit" @click="$refs.BaseSingleAddressComponent.validateForm()" class="btn btn_default"><svg-icon name="solid/paper-plane" /> Submit</button>
        </p>
      </div>
    </Form>
    <transition name="fade" mode="out-in" v-if="showResend">
      <div class="modal modal_wrap">
        <div class="modal_popup">
          <div class="form__warningBox">
            <h2 class="titlePage_green titlePage_textLeft">Application approved</h2>
            <p class="text text_white">The application has been approved by Starline Security. Check your email to complete registration and create password.</p>
            <div class="row">
              <div class="col-12">
                <button type="submit" class="btn btn_transparent" @click="resend" ><svg-icon name="solid/envelope" /> Re-send email</button>
                <button class="btn btn_transparent ml-1" @click="toLogin"><svg-icon name="solid/right-to-bracket" /> Login</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { maska } from 'maska'
import BaseSingleAddressComponent from "@/components/commons/BaseSingleAddressComponent";


  import {mapGetters} from 'vuex';

  export default {
    name: "Registration",

    directives: {
      maska
    },
    components : {
      BaseSingleAddressComponent
    },

    data() {
      return {
        firstAttempt : true,
        key: 0,
        applyForTrading: false,
        samePhone: false,
        sameEmail: false,
        sameTredName: false,
        samePhoneDisabled: false,
        sameEmailDisabled: false,
        sameTredNameDisabled: false,

        user: {
          name: '',
          last_name: '',
          phone: '',
          email: '',
          mobile_phone: '',

          business_name: '',
          trading_name: '',
          abn: '',
          register_for_gst: true,
          same_phone: true,
          business_email: '',
          same_email: true,
          same_tname: true,

          address: '',
          suburb: '',
          state_id: '',
          postcode: '',

          qualification: '',

          experience_id: '',
          number_of_employers_id: '',
          public_liability_id: 1,
          require_trading_account: '',
        },
      };
    },

    methods: {
      handleSubmit() {
        this.user.phone =  this.user.phone.replace(/\s+/g, '');
        this.user.mobile_phone = this.user.mobile_phone.replace(/\s+/g, '');
        this.user.state_id = this.user.state
        this.$store.dispatch('registration/postUser', this.user);
        /*this.$validator.validate().then(valid => {
          if (valid) {
            this.$store.dispatch('registration/postUser', this.user);
          }
        });*/
      },
      disableSame(k) {
        this[k] = false;
        this.user[k + 'Disabled'] = true;
      },

      actionsOnMobile() {
        if (this.samePhone) {
          this.user.phone = event.target.value.replace(/\s+/g, '');
        }
      },

      actionsOnEmail() {
        if (this.sameEmail) {
          this.user.business_email = event.target.value;
        }
      },

      actionsOnName() {
        if (this.sameTredName) {
          this.user.trading_name = event.target.value;
        }
      },

      opacity(elem) {
        if (!elem) {
          return 'color: #B5B5B5';
        }
      },
        toLogin(){
            this.$store.commit('status/clearMessage')
            this.$router.push({name: 'login'})
        },
        resend(){
            this.$store.dispatch('registration/resendEmail', {
                email: this.user.email
            })
        }

    },
    computed: {
      ...mapGetters({
        owners: 'registration/owners',
        experience: 'registration/experience',
        state: 'registration/state',
        liabilities: 'registration/liabilities',
          showResend: 'registration/showResend'
      }),
    },

    watch: {
      'user.require_trading_account' (value) {
        if(!value && this.firstAttempt){
          this.firstAttempt = false
          this.applyForTrading = value
          this.key ++

          setTimeout(() => {
            this.user.require_trading_account = this.applyForTrading
          },400)
        }
      },
      samePhone(val) {
        if (val === true) {
          this.user.phone = this.user.mobile_phone.replace(/\s+/g, '');
        }
        this.samePhoneDisabled = false;
      },

      sameEmail() {
        if (this.sameEmail === true) {
          return this.user.business_email = this.user.email;
        }
        else if (this.sameEmailDisabled) {
          return this.user.business_email = '';
        }
        this.sameEmailDisabled = false;
      },

      sameTredName() {
        if (this.sameTredName === true) {
          return this.user.trading_name = this.user.business_name;
        }
        else if (this.sameTredNameDisabled) {
          return this.user.trading_name = '';
        }
        this.sameTredNameDisabled = false;
      },
    },

    created() {
      this.$store.dispatch('registration/getOwners');
      this.$store.dispatch('registration/getExperience');
      this.$store.dispatch('registration/getState');
      this.$store.dispatch('registration/getLiabilities');
    },

  }
</script>

<style scoped lang="scss">
  @import "../assets/scss/utils/vars";
</style>
